import { lazy, Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/error-pages/NotFound';
import Loading from '../shared/components/Loading';
import RequireAuth from './RequireAuth';


const Tags = lazy(() => import('../pages/tags/Tags'));
const PatchingCritical = lazy(() => import('../pages/patching/PatchingCritical'));
const BackupVMOndemand = lazy(() => import('../pages/backup/vm/BackupVMOndemand'));
const BackupVMRestore = lazy(() => import('../pages/backup/vm/BackupVMRestore'));
const BackupDatabaseOndemand = lazy(() => import('../pages/backup/database/BackupDatabaseOndemand'));
const BackupDatabaseRestore = lazy(() => import('../pages/backup/database/BackupDatabaseRestore'));
const Monitoring = lazy(() => import('../pages/monitoring/Monitoring'));
const Logging = lazy(() => import('../pages/logging/Logging'));
const ProvisionedProducts = lazy(() => import('../pages/blueprint/ProvisionedProducts/ProvisionedProducts'));
const BlueprintCreateVM = lazy(() => import('../pages/blueprint/vm/BlueprintCreateVM'));
const BlueprintCreateDatabase = lazy(() => import('../pages/blueprint/database/BlueprintCreateDatabase'));
const BlueprintCreateDynamodbTable = lazy(() => import('../pages/blueprint/database/BlueprintCreateDynamodbTable'));
const BlueprintCreateVpc = lazy(() => import('../pages/blueprint/network/BlueprintCreateVpc'));
const BlueprintCreateS3Bucket = lazy(() => import('../pages/blueprint/storage/BlueprintCreateS3Bucket'));
const BlueprintChangeVMPowerState = lazy(() => import ('../pages/blueprint/vm/BlueprintChangeVMPowerState'));
const BlueprintCreateBeanstalk = lazy(() => import ('../pages/blueprint/website/BlueprintCreateBeanstalk'));
const BlueprintCreateStaticWebsite = lazy(() => import('../pages/blueprint/website/BlueprintCreateStaticWebsite'));

const { Content } = Layout;


const PageRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Content><div style={{ padding: '12px 12px 0 12px', minHeight: 360 }}><Outlet /></div></Content>} >
        {/* Dashboard */}
        <Route index element={<Dashboard />} />
        {/* Tags */}
        <Route path="tags" element={<RequireAuth><Tags /></RequireAuth>} />
        {/* Patching */}
        <Route path='patching' element={<Outlet />}>
          <Route index element={<NotFound />} />
          <Route path="critical" element={<RequireAuth><PatchingCritical /></RequireAuth>} />
        </Route>
        {/* Backup */}
        <Route path='backup' element={<Outlet />}>
          <Route index element={<NotFound />} />
          <Route path='vm' element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="ondemand" element={<RequireAuth><BackupVMOndemand /></RequireAuth>} />
            <Route path="restore" element={<RequireAuth><BackupVMRestore /></RequireAuth>} />
          </Route>
          <Route path='database' element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="ondemand" element={<RequireAuth><BackupDatabaseOndemand /></RequireAuth>} />
            <Route path="restore" element={<RequireAuth><BackupDatabaseRestore /></RequireAuth>} />
          </Route>
        </Route>
        {/* Monitoring */}
        <Route path="monitoring" element={<Suspense fallback={<Loading />}><Monitoring /></Suspense>} />
        {/* Logging */}
        <Route path="logging" element={<Suspense fallback={<Loading />}><Logging /></Suspense>} />
        {/* Blueprint */}
        <Route path='blueprint' element={<Outlet />}>
          <Route index element={<NotFound />} />
          <Route path="provisioned-products" element={<RequireAuth><ProvisionedProducts /></RequireAuth>} />
          <Route path='vm' element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="createvm" element={<RequireAuth><BlueprintCreateVM /></RequireAuth>} />
            <Route path="powerstate" element={<RequireAuth><BlueprintChangeVMPowerState /></RequireAuth>} />
          </Route>
          <Route path='database' element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="createdb" element={<RequireAuth><BlueprintCreateDatabase /></RequireAuth>} />
            <Route path="createdynamodb" element={<RequireAuth><BlueprintCreateDynamodbTable /></RequireAuth>} />
          </Route>
          <Route path='network' element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="createvpc" element={<RequireAuth><BlueprintCreateVpc /></RequireAuth>} />
          </Route>
          <Route path='storage' element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="creates3bucket" element={<RequireAuth><BlueprintCreateS3Bucket /></RequireAuth>} />
          </Route>
          <Route path="Website" element={<Outlet />}>
            <Route index element={<NotFound />} />
            <Route path="createBeanstalk" element={<RequireAuth><BlueprintCreateBeanstalk /></RequireAuth>} />
            <Route path="createStaticWebsite" element={<RequireAuth><BlueprintCreateStaticWebsite /></RequireAuth>} />
          </Route>
        </Route>
        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
};


export default PageRoutes;
